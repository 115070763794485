/* additional js here */
var template_path = $('html').data('path');
var child_path = template_path.replace('webwerk', 'webwerk-child');

// Teambox
$(document).ready(function () {
    $('.teambox figure:not(:has(img))').remove();

    $('.teambox').each(function () {
        if ($(this).children('figure').length > 0) {
            $(this).addClass('teambox--img');
        }
    })
});